import './App.css';
import React from 'react';
import {ethers} from "ethers";
import nftwatchContractAbi from "./abi.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
 
let nftwatchContractAddr = "0xDE0622dCdee880972e86DaE04aC2097eA86D9648";

// Reload page if network or wallet is changed
if (window.ethereum) {
    window.ethereum.on('networkChanged', function (networkId) {
        window.location.reload();
    });
}

class MyLoginButton  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: props.disabled,
            label: props.label,
            afterLabel: props.afterLabel
        };

        this.handleClick = this.handleClick.bind(this);
    }
    
    async handleClick() {
        const oldLabel = this.state.label;
        
        this.setState({
            label: "⌛",
            disabled: "disabled"
        });
        
        await this.props.clickHandler();

        this.setState({
            label: oldLabel,
            disabled: ""
        });
    }
        
    render () {
        return (
            <div className="MyButton">
                <button onClick={this.handleClick} disabled={this.state.disabled}>{this.state.label}</button>{this.state.afterLabel}
            </div>
        );
    }
}

class MyClaimButton  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: props.disabled,
            label: props.label,
            afterLabel: props.afterLabel
        };

        this.handleClick = this.handleClick.bind(this);
    }
    
    async handleClick() {
        const oldLabel = this.state.label;
        
        this.setState({
            label: "⌛",
            disabled: "disabled"
        });
        
        await this.props.clickHandler();

        this.setState({
            label: oldLabel,
            disabled: ""
        });
    }
    
    render () {
        return (
            <div className="MyButton">
                <button onClick={this.handleClick} disabled={this.state.disabled}>{this.state.label}</button>{this.state.afterLabel}
            </div>
        );
    }
}

class UserPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            hasClaimed: false,
            address: null,
            balance: null,
            isPolygonTestnet: true,
            userMessage: ""
        };

        this.tryLogin = this.tryLogin.bind(this);
        this.tryClaim = this.tryClaim.bind(this);
    }
    
    // Allow login
    async tryLogin() {
        if (!this.state.isLoggedIn) {
            const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
            if (!ethProvider) {
                console.error("Please install MetaMask !");
                return;
            }

            const currNetwork = await ethProvider.getNetwork();
            const isPolygonTestnet = (currNetwork.chainId.toString() === "137");

            if (!isPolygonTestnet) {
                console.error('Please switch to Polygon Network and reload');
                this.setState({
                    isPolygonTestnet: isPolygonTestnet,
                });
                return;
            }


            try {
                await ethProvider.send('eth_requestAccounts', []);
                const signer = await ethProvider.getSigner();
        
                const signerAddress = await signer.getAddress();
                const signerBalance = await signer.getBalance();
        
                const NFTWatch = new ethers.Contract(nftwatchContractAddr, nftwatchContractAbi, signer);
                const userBalance = await NFTWatch.balanceOf(signerAddress, 1);
                        
                if (userBalance > 0) {
                    this.setState({
                        isLoggedIn: true,
                        hasClaimed: true,
                        userMessage: "You already own an NFT watch on this address",
                        address: signerAddress,
                        balance: signerBalance
                    });            
                } else {        
                    this.setState({
                        isLoggedIn: true,
                        address: signerAddress,
                        balance: signerBalance
                    });
                }
                
                window.ethereum.on('accountsChanged', function (accounts) {
                    window.location.reload();
                });
                
                toast("Logged in !");
            } catch (error) {
                console.log(error);
            }
        }
    }

    // Permet de Claim le NFT 
    async tryClaim() {
        const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
        if (!ethProvider) {
            this.setState({userMessage: "Please install MetaMask !"});
            return;
        }

        const signer = await ethProvider.getSigner();

        const NFTWatch = new ethers.Contract(nftwatchContractAddr, nftwatchContractAbi, signer);
        try {
            await NFTWatch.mintmine();
            this.setState({
                userMessage: "OK ! Check your wallet ;)",
                hasClaimed: true
            });
            toast("Watch Claimed !");
        } catch (error) {
            this.setState({userMessage: "Claim cancelled"});
            console.log(error);
        }      
    }

    render () {
        if (!window.ethereum) {
            console.error("Please install MetaMask !");
            return <div className="header-message">Please install MetaMask !</div>;
        }
        if (!this.state.isPolygonTestnet) {
            return <div className="header-message">Please switch to Polygon Network</div>;
        }
        if (!this.state.isLoggedIn) {
            return <MyLoginButton clickHandler={this.tryLogin} isDisabled={false} label="Connect Metamask" afterLabel="" />;
        } else {
            if (!this.state.hasClaimed) {
                return <div>
                <MyClaimButton clickHandler={this.tryClaim}  isDisabled={false} label="Claim My NFT Watch" afterLabel="" />
                <p className="signerAddress">Addr : {this.state.address}</p>
                <p className="userMessage">{this.state.userMessage}</p>
                </div>;
            } else {
                return <div>
                    <p className="signerAddress">Addr : {this.state.address}</p>
                    <p className="userMessage">{this.state.userMessage}</p>
                </div>;
            }
        }
    }
}


function App() { 
    return (
    <div className="App">
      <ToastContainer />
      <header className="App-header">
        <img decoding="async" src="/logo.png" title="NFT Watch" alt="NFT Watch" />
        <UserPane />
      </header> 
    </div>
    );
}

export default App;
